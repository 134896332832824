import React, { useState } from 'react';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';

import * as styles from './BackdropImage.module.scss';
import { ContentfulImage } from '../../hooks/useContentfulImage.hook';
import classNames from 'classnames';

type BackdropImageProps = Omit<GatsbyImageProps, 'image' | 'alt'> & {
  imageData: ContentfulImage;
  alt?: string;
  noShading?: boolean;
  gradient?: boolean;
};

/**
 * Displays a absolutely positioned image with width 100% and height 100%
 * in the parent element. With a gradient on top. Stays at z-index 0
 * @returns
 */
function BackdropImage({ imageData, noShading, gradient, ...props }: BackdropImageProps) {
  const [isVisible, setVisible] = useState(false);
  const imageClasses = classNames({
    [styles.image]: true,
    [styles.noShading]: noShading,
    [styles.visible]: isVisible,
    [styles.gradient]: gradient,
    backdropImage: true,
    notLoaded: !isVisible,
    loaded: isVisible,
  });

  return (
    <div className={styles.imageWrapper}>
      <GatsbyImage
        image={imageData?.imageData}
        objectPosition="right"
        onLoad={() => setVisible(true)}
        onStartLoad={({ wasCached }) => wasCached && setVisible(true)}
        className={imageClasses}
        loading="eager"
        alt={props.alt || imageData.title}
        {...props}
      />
    </div>
  );
}

export default BackdropImage;
