import React, { ReactChild, ReactNode } from 'react';
import classNames from 'classnames';

import * as styles from './Card.module.scss';

import PrimaryButton from '../PrimaryButton/PrimaryButton';
import BackdropImage from '@components/BackdropImage';
import { ContentfulImage } from '../../hooks/useContentfulImage.hook';
import { Theme } from '@src/styles/theme';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import useIsMobile from '../../hooks/useIsMobile.hook';

type CardImageProps = {
  children?: ReactNode;
  imageData?: ContentfulImage;
};
export function CardImage({ children, imageData }: CardImageProps) {
  return (
    <div className={styles.cardImageContent}>
      {imageData ? (
        <GatsbyImage
          loading="eager"
          image={imageData.imageData}
          alt={imageData.title}
          objectFit="contain"
        />
      ) : (
        children
      )}
    </div>
  );
}

export type CardProps = Omit<React.HTMLProps<HTMLDivElement>, 'ref'> & {
  title?: string;
  text?: string;
  theme?: Theme | 'purple' | 'soft-2' | 'dark-purple' | 'purple-light';
  cta?: string;
  ctaHref?: string;
  wide?: boolean;
  backdropImage?: ContentfulImage;
  contentfulImg?: ContentfulImage;
  style?: React.CSSProperties;
  centerContent?: boolean;
  small?: boolean;
  width?: number | string;
  height?: number | string;
  minWidth?: number | string;
  className?: string;
  scrollable?: boolean;
  freeSize?: boolean;
  noBorder?: boolean;
  noShading?: boolean;
  backdropContent?: ReactChild;
  childrenOverflow?: 'hidden' | 'visible';
  onClick?: React.MouseEventHandler<HTMLElement>;
  snap?: boolean;
  badge?: ReactChild;
  ctaElement?: ReactChild;
};

function Card({
  title,
  text,
  theme = 'white',
  wide,
  cta,
  ctaHref,
  backdropImage,
  contentfulImg,
  children,
  style,
  width,
  minWidth,
  height,
  centerContent,
  small,
  className,
  scrollable,
  freeSize,
  noBorder,
  noShading,
  childrenOverflow,
  backdropContent,
  onClick,
  snap,
  badge,
  ctaElement,
  ...restProps
}: CardProps) {
  const backdrop = backdropImage ? (
    <BackdropImage
      noShading={noShading}
      imageData={backdropImage}
      style={{ borderRadius: 16 }}
      gradient={!wide}
    />
  ) : (
    undefined
  );

  const s = {
    width,
    height,
    minWidth,
    ...style,
  };

  const isMobile = useIsMobile();

  const classes = classNames({
    [className]: className,
    [styles.card]: true,
    [styles.purple]: theme === 'purple',
    [styles.soft]: theme === 'soft',
    [styles.dark]: theme === 'dark',
    [styles.black]: theme === 'black',
    [styles.softTwo]: theme === 'soft-2',
    [styles.darkPurple]: theme === 'dark-purple',
    [styles.purpleLight]: theme === 'purple-light',
    [styles.wide]: wide,
    [styles.centered]: centerContent,
    [styles.withImage]: backdrop,
    [styles.small]: small,
    [styles.scrollable]: scrollable,
    [styles.freeSize]: freeSize,
    [styles.noBorder]: noBorder,
    [styles.clickable]: onClick,
    [styles.withCta]: cta,
    [styles.childrenVisible]: childrenOverflow === 'visible',
    [styles.cardSnapContainer]: snap,
  });

  const buttonColor = theme === 'purple' ? 'dark' : 'white';

  const isClickable = !!(ctaHref || onClick);
  const Element = isClickable ? 'div' : 'div';

  const extraProps = isClickable
    ? {
        asWrapper: true,
        href: ctaHref,
      }
    : {};

  function childContent() {
    if (children) {
      return <div className={styles.children}>{children}</div>;
    } else if (isMobile && contentfulImg) {
      return (
        <GatsbyImage
          className={styles.children}
          image={contentfulImg.imageData}
          alt={contentfulImg.title}
          objectFit="contain"
        />
      );
    } else {
      return;
    }
  }

  const hasBackdropImage = typeof backdropImage !== 'undefined' ? true : false;

  return (
    <Element
      className={classes}
      style={s}
      onClick={onClick}
      aria-label={cta}
      {...restProps}
      {...extraProps}
    >
      {backdrop}
      <div
        className={classNames({
          [styles.cardContent]: true,
          [styles.wide]: wide,
        })}
      >
        {badge && <div className={styles.badge}>{badge}</div>}
        {(title || text) && (
          <div
            className={classNames({
              [styles.textContent]: true,
              [styles.wide]: wide,
            })}
          >
            {title && (
              <ReactMarkdown
                className={classNames({
                  [styles.title]: true,
                  [styles.wide]: wide,
                  [styles.textBackdropImage]: hasBackdropImage,
                })}
              >
                {title.replaceAll('<br>', '  \n')}
              </ReactMarkdown>
            )}
            {text && (
              <ReactMarkdown
                className={classNames({
                  [styles.text]: true,
                  [styles.wide]: wide,
                  [styles.textBackdropImage]: hasBackdropImage,
                })}
              >
                {text.replaceAll('<br>', '  \n')}
              </ReactMarkdown>
            )}
          </div>
        )}

        {childContent()}

        {cta && (
          <div className={styles.ctaButton}>
            <PrimaryButton theme={buttonColor} href={ctaHref} onClick={onClick}>
              {cta}
            </PrimaryButton>
          </div>
        )}
        {ctaElement && ctaElement}
      </div>
      {backdropContent && (
        <div className={styles.backdropContent}>{backdropContent}</div>
      )}
      {contentfulImg && !isMobile && (
        <GatsbyImage
          className={styles.image}
          image={contentfulImg.imageData}
          alt={contentfulImg.title}
          objectFit="contain"
        />
      )}
    </Element>
  );
}

export default Card;
