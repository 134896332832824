import React from 'react';
import { graphql } from 'gatsby';

import SEO from '@components/seo';

import Layout from '@components/Layout/Layout';
import TextHero from '@src/components/TextHero';
import EcbRates from '@src/components/hjalp-components/ecbRates';
import Container from '@src/components/Container';
import SubNavPills from '@src/components/SubNavPills/SubNavPills';
import { ContentfulContactComponent, ContentfulFaq } from '../../graphql-types';
import FaqList from '@src/components/FaqList';
import ContactMethods from '@src/components/ContactMethods';

function Hjalp({ data, pageContext }) {
  const {
    contentfulPage: {
      customContactMethods,
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
    },
  } = data;

  const headLine = data.contentfulPage.headLine;

  const faqContent = data.contentfulPage.pageContent[0] as ContentfulFaq;
  const contactMethods = customContactMethods as ContentfulContactComponent;

  const selectedGroup =
    pageContext?.faqGroup || faqContent.questionGroups[0].contentfulid;

  const selectedIndex = faqContent.questionGroups.findIndex(
    f => f.contentfulid === selectedGroup,
  );

  return (
    <Layout path="/hjalp">
      <SEO title={headLine} />
      <TextHero
        title={pageHeader}
        subtitle={pageHeaderDescription}
        style={{ marginBottom: 32, marginTop: 100 }}
      />
      <Container>
        <ContactMethods methods={contactMethods.methods} />
      </Container>
      <SubNavPills
        links={faqContent.questionGroups.map(g => ({
          href: `/hjalp/${g.contentfulid}`,
          name: g.title,
          selected: g.contentfulid === selectedGroup,
          id: g.contentfulid,
        }))}
      />
      <Container>
        <FaqList
          hideTitle
          questions={faqContent.questionGroups[selectedIndex].questions}
        />
        <EcbRates />
      </Container>
    </Layout>
  );
}

export default Hjalp;

export const query = graphql`
  query Hjalp {
    contentfulPage(pageUrl: { eq: "/hjalp2.0" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageContactHeader
      customContactMethods {
        methods {
          link
          linkText
          extraLink
          extraLinkText
          title
          description {
            description
          }
        }
      }
      pageContent {
        ... on ContentfulFaq {
          header
          questionGroups {
            title
            contentfulid
            questions {
              ... on ContentfulQuestion {
                question
                answer {
                  answer
                }
              }
            }
          }
        }
      }
    }
  }
`;
