// extracted by mini-css-extract-plugin
export var backdropContent = "Card-module--backdropContent--90ae8";
export var black = "Card-module--black--5d663";
export var card = "Card-module--card--fde84";
export var cardContent = "Card-module--cardContent--96469";
export var cardImageContent = "Card-module--cardImageContent--d9b4b";
export var cardSnapContainer = "Card-module--cardSnapContainer--fc453";
export var centered = "Card-module--centered--9a481";
export var children = "Card-module--children--99f76";
export var clickable = "Card-module--clickable--dccc8";
export var ctaButton = "Card-module--ctaButton--c8733";
export var dark = "Card-module--dark--11099";
export var darkPurple = "Card-module--darkPurple--329b3";
export var freeSize = "Card-module--freeSize--30334";
export var image = "Card-module--image--f5f28";
export var noBorder = "Card-module--noBorder--b089a";
export var purple = "Card-module--purple--4bd5d";
export var purpleLight = "Card-module--purpleLight--47512";
export var scrollable = "Card-module--scrollable--efac0";
export var small = "Card-module--small--25229";
export var soft = "Card-module--soft--6d080";
export var softTwo = "Card-module--softTwo--e67eb";
export var text = "Card-module--text--d91db";
export var textBackdropImage = "Card-module--textBackdropImage--8f7c5";
export var textContent = "Card-module--textContent--bb879";
export var title = "Card-module--title--6b9de";
export var wide = "Card-module--wide--307cc";
export var withImage = "Card-module--withImage--0a8b1";